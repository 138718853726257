import { useState } from "react"
import { Property } from "../../reactor/Types/Type"
import { useDocumentPropertyDeclarations } from "../client"
import { RButton } from "./Buttons"
import { CodeEdit } from "./CodeEdit"
import { PropRow } from "./PropRow"
import { useEditableContext } from "../../packages/editing/EditableContext"
import { useDocument } from "./DocumentContext"

export function TypeScriptView({
    obj,
    property,
    label,
    mode,
}: {
    obj: any
    label?: string
    property: Property
    mode?: "json-stringify"
}) {
    const [editing, setEditing] = useState(false)

    return (
        <PropRow
            buttons={[]}
            badge={
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RButton
                        variant="secondary"
                        icon={editing ? "check" : "pencil"}
                        onClick={() => setEditing(!editing)}
                    >
                        {editing ? "Done" : "Edit"}
                    </RButton>
                </div>
            }
            description={property.description}
            label={label}
        >
            {editing ? (
                <TypeScriptViewInner obj={obj} property={property} mode={mode} />
            ) : (
                <pre style={{ backgroundColor: "#f8f8f8", padding: 16 }}>
                    {mode === "json-stringify"
                        ? JSON.stringify(obj[property.name], null, 2)
                        : obj[property.name]}
                </pre>
            )}
        </PropRow>
    )
}

function TypeScriptViewInner({
    obj,
    property,
    mode,
}: {
    obj: any
    property: Property
    mode?: "json-stringify"
}) {
    const declarationsFunc = property.tags?.declarations
    const doc = useDocument()
    const ec = useEditableContext()

    const { data: declarations } = useDocumentPropertyDeclarations(
        !!declarationsFunc
            ? {
                  document: doc,
                  object: obj !== doc ? obj : null,
                  declarationsFunc,
                  includeMutations: property.tags?.includeMutations,
              }
            : null
    )
    return (
        <CodeEdit
            text={
                mode === "json-stringify"
                    ? JSON.stringify(obj[property.name], null, 2)
                    : obj[property.name]
            }
            declarations={declarations?.code || ""}
            language={mode === "json-stringify" ? "json" : "typescript"}
            onEdited={(code) => {
                if (mode === "json-stringify") {
                    try {
                        obj[property.name] = JSON.parse(code)
                    } catch (e: any) {
                        alert(e.message)
                        return
                    }
                } else {
                    obj[property.name] = code
                }
                ec.invalidate()
            }}
        />
    )
}
