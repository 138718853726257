export type Editor<T> = (props: { obj: T; prop: keyof T }) => JSX.Element

/**
 * A list of functions that can be used to launch a custom editor for a
 * TypeScript property. Use the `@editor FunctionName` attribute to point to a
 * function.
 */
export const Editors = new Map<string, Editor<any>>()

export function Editor(name: string, editor: Editor<any>) {
    Editors.set(name, editor)
}
