import { useEffect } from "react"
import { Assert } from "../Helpers"

let ws: Promise<WebSocket> | undefined
let socket: WebSocket
export function useChangeNotifications(
    collection: string,
    /**
     * The primary key to listen for changes in. If not provided, the entire
     * collection will be listened to.
     */
    primaryKey: string | undefined,
    refresh: (changeNotifications: boolean) => void,
    /** Enables or disables this hook, without changing the number of hooks
     * created in the React render. */
    canUseChangeNotifications = true
) {
    // Listen for Change Stream
    useEffect(() => {
        if (!canUseChangeNotifications) return

        let listenersAdded = false
        let subscriptionAdded = false
        let wsOpenListenerAdded = false
        async function createWebSocket() {
            if (!ws) {
                ws = new Promise(async (resolve) => {
                    const { getWebSocketTicketForStudioUser } = await import("../../studio/client")
                    const ticket = await getWebSocketTicketForStudioUser()
                    const proto = window.location.protocol === "https:" ? "wss:" : "ws:"
                    const host = window.location.hostname
                    let port = window.location.port
                    if (port === "3001") port = "3000"
                    socket = new WebSocket(
                        `${proto}//${host}:${port}/api/studio/documents/changed?ticket=${ticket.ticket}`
                    )
                    resolve(socket)
                })
            }

            socket = await ws

            if (socket.readyState !== socket.OPEN) {
                socket.addEventListener("open", onOpen)
                wsOpenListenerAdded = true
            } else {
                subscribe()
                subscriptionAdded = true
            }
            socket.addEventListener("message", onMessage)
            listenersAdded = true
        }
        void createWebSocket()

        function subscribe() {
            const s = Assert(socket)
            s.send(JSON.stringify({ subscribe: collection, primaryKey }))
        }
        function unsubscribe() {
            const s = Assert(socket)
            s.send(JSON.stringify({ unsubscribe: collection, primaryKey }))
        }

        function onOpen() {
            subscribe()
            subscriptionAdded = true
        }

        function onMessage({ data }: { data: string }) {
            const msg = JSON.parse(data.toString())
            if (typeof msg === "object" && "changed" in msg) {
                if (
                    msg.changed === collection &&
                    (primaryKey === undefined || msg.primaryKey === primaryKey)
                ) {
                    refresh(true)
                }
            }
        }

        return () => {
            if (listenersAdded) socket?.removeEventListener("message", onMessage)
            if (wsOpenListenerAdded) socket?.removeEventListener("message", onOpen)
            if (subscriptionAdded) unsubscribe()
        }
    }, [refresh, collection, canUseChangeNotifications])
}
