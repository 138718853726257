import { Lazy } from "../code-splitting/Lazy"
import { Outlet } from "react-router-dom"
import { CurrentLocaleProvider } from "../localization/client-side/CurrentLocaleContext"
import { DummyEditableContextProvider } from "./EditableContext"
import { DummySelectionContext, SelectionContext } from "./SelectionContext"

export function EditableSiteLazy(props: { condition: boolean; plugins?: () => Promise<void> }) {
    return (
        <Lazy
            load={props.condition}
            target={async () => {
                if (props.plugins) await props.plugins()
                return (await import("./EditableSite")).EditableSite
            }}
            fallback={NonEditableSite}
        />
    )
}

function NonEditableSite() {
    return (
        <CurrentLocaleProvider>
            <SelectionContext.Provider value={DummySelectionContext}>
                <DummyEditableContextProvider>
                    <Outlet />
                </DummyEditableContextProvider>
            </SelectionContext.Provider>
        </CurrentLocaleProvider>
    )
}
