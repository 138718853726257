import React, { useEffect, useState } from "react"
import { Markdown, Uuid } from "../../reactor/Types/Primitives"
import { Toolbar } from "./Toolbar"
import { FileToUrl, ImageToUrl, UndoImageToUrl } from "../../reactor/Types/File"
import { InsertImage } from "./InsertImage"
import type { EditorState } from "draft-js"
import {
    GetMarkdownSnippetDto,
    getFileInfo,
    useFileInfo,
    useMarkdownSnippet,
} from "../../studio/client"
import { ColorStyles } from "../ui"
import { useCurrentLocale } from "../localization/client-side/useLocalize"
import { ClientSideLocalize } from "../localization/client-side/Dictionary"
import { Icon } from "../../studio/Views/Icon"
import { useContextualModal } from "../modal/Modal"
import { RButton } from "../../studio/Views/Buttons"
import { MarkdownView } from "./MarkdownView"
import { useEditableContext, useVersionKey } from "../editing/EditableContext"
import { Lazy } from "../code-splitting/Lazy"

export type InsertedFile = {
    /** The file ID of the uploaded file */
    id: Uuid
    /** The name of the file */
    name: string
}

export type MarkdownEditorProps = {
    style: "studio" | "wysiwyg"
    value: Markdown | undefined
    onChanged: (value: Markdown) => void
    onDrop(e: React.DragEvent): Promise<InsertedFile | undefined>
    onDone?: () => void
    onFocus?: () => void
    onBlur?: () => void
    autoFocus?: boolean
    /**
     * What markdown snippet is currently being edited. This is used to
     * prevent inviting you to embed the same snippet inside itself.
     */
    markdownSnippetId?: Uuid<"MarkdownSnippet">
}
export function MarkdownEditor(props: MarkdownEditorProps) {
    const versionKey = useVersionKey()
    const [editor, setEditor] = useState<JSX.Element | null>(null)
    useEffect(() => {
        async function load() {
            const draftJs = await import("draft-js")
            const draftJsMdConverter = await import("draftjs-md-converter")
            setEditor(
                <MarkdownEditorInternal
                    key={versionKey}
                    {...props}
                    draftJs={draftJs}
                    draftJsMdConverter={draftJsMdConverter}
                    style={props.style}
                    autoFocus={
                        props.autoFocus !== undefined ? props.autoFocus : props.style === "wysiwyg"
                    }
                    markdownSnippetId={props.markdownSnippetId}
                />
            )
        }
        void load()
    }, [
        props.value,
        versionKey,
        props.onChanged,
        props.onDrop,
        props.onDone,
        props.markdownSnippetId,
    ])
    return editor
}

function MarkdownEditorInternal(
    props: MarkdownEditorProps & {
        draftJs: typeof import("draft-js")
        draftJsMdConverter: typeof import("draftjs-md-converter")
        autoFocus: boolean
        markdownSnippetId?: Uuid<"MarkdownSnippet">
    }
) {
    const [ref, setRef] = useState<any>(null)

    const getEditorStateFromProps = () =>
        props.draftJs.EditorState.createWithContent(
            props.draftJs.convertFromRaw(
                props.draftJsMdConverter.mdToDraftjs(props.value?.valueOf() || "")
            )
        )

    const [editorState, setEditorState] = useState<EditorState>(getEditorStateFromProps)
    function onNewEditorState(e: EditorState) {
        setEditorState(e)
        const newValue = props.draftJsMdConverter.draftjsToMd(
            props.draftJs.convertToRaw(e.getCurrentContent())
        ) as any as Markdown

        // If the value is undefined and the new value is an empty string, we
        // don't want to call onChanged, because that would mark the document as
        // dirty before we have started typing anything
        if (props.value === undefined && newValue.valueOf() === "") return

        if (props.value !== newValue) {
            props.onChanged(newValue)
        }
    }

    useEffect(() => {
        if (props.autoFocus && ref) ref.focus()
    }, [ref, props.autoFocus])

    return (
        <div
            className={props.style === "studio" ? "form-control" : undefined}
            style={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                position: "relative",
                border:
                    props.style === "wysiwyg" ? "1px solid " + ColorStyles.primary[400] : undefined,
            }}
        >
            <Toolbar
                editorState={editorState}
                setEditorState={onNewEditorState}
                draftJs={props.draftJs}
                style={props.style}
                currentMarkdownSnippet={props.markdownSnippetId}
                onDone={props.onDone}
            />
            <div
                style={{ padding: props.style === "studio" ? 16 : 0 }}
                onDrop={async (e) => {
                    const file = await props.onDrop(e)
                    if (file) {
                        const fileInfo = await getFileInfo(file.id.valueOf())
                        onNewEditorState(
                            InsertImage(
                                props.draftJs,
                                editorState,
                                fileInfo.mimetype.startsWith("image")
                                    ? ImageToUrl(file.id as any)
                                    : FileToUrl(file.id as any),
                                file.name
                            )
                        )
                    }
                }}
            >
                <props.draftJs.Editor
                    ref={setRef}
                    customStyleMap={MarkdownEditor.customStyleMap}
                    editorState={editorState}
                    onChange={onNewEditorState}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    blockRendererFn={(b) => {
                        if (b.getType() === "atomic") {
                            return {
                                component: AtomicBlock,
                                editable: false,
                            }
                        }
                        return null
                    }}
                />
            </div>
        </div>
    )
}
/**
 * Allows the model to override the default style map for Draft.js.
 */
MarkdownEditor.customStyleMap = undefined as any // Draft.Component.Base.DraftStyleMap | undefined

function AtomicBlock(props: any) {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0))
    const type = entity.getType()

    if (type === "IMAGE") {
        const { src, alt, fileName } = entity.getData()

        return (
            <Media
                src={src}
                // Due to a quirk in draftjs-md-converter, the alt text is placed in
                // fileName instead of alt. This is a workaround.
                alt={fileName}
            />
        )
    } else {
        return <div>Unknown atomic block type</div>
    }
}

function Media(props: { src: string; alt: string }) {
    const id = UndoImageToUrl(props.src)
    const needFileInfo =
        !props.alt?.startsWith("#IFRAME") && !props.alt?.startsWith("#MARKDOWN_SNIPPET")

    const { data: fileInfo } = useFileInfo(needFileInfo ? id : null)

    if (props.alt?.startsWith("#IFRAME")) {
        // Styles are encoded like this: #IFRAME{"width":100,"height":400}
        const style = props.alt.length > 7 ? JSON.parse(props.alt.slice(7)) : {}

        if (props.src.startsWith("/") && !props.src.startsWith("//")) {
            return (
                <iframe
                    src={props.src}
                    style={{ width: "100%", maxWidth: 480, height: 500, ...style }}
                />
            )
        } else {
            return (
                <div style={{ padding: 8, backgroundColor: "#eee", color: "red" }}>
                    Only relative URLs are supported for embedding
                </div>
            )
        }
    }

    if (props.alt?.startsWith("#MARKDOWN_SNIPPET")) {
        const id = props.src
        return <EditableMarkdownSnippetView id={id as any} />
    }

    if (fileInfo?.mimetype.startsWith("video")) {
        return (
            <video
                muted={true}
                autoPlay={true}
                controls={true}
                src={props.src}
                style={{ width: "100%", maxWidth: 480 }}
            />
        )
    }
    if (fileInfo?.mimetype.startsWith("application/lottie+json")) {
        return (
            <div>
                <Lazy
                    target={async () => {
                        const Lottie = (await import("../../studio/Views/Lottie")).Lottie
                        return () => <Lottie file={id as any} style={{ width: 400, height: 400 }} />
                    }}
                />
            </div>
        )
    }

    if (fileInfo?.mimetype.startsWith("image") || props.src.includes("/api/images")) {
        return <img src={props.src} style={{ width: "100%", maxWidth: 480 }} />
    }

    return <div>Loading...</div>
}

function EditableMarkdownSnippetView({ id }: { id: Uuid<"MarkdownSnippet"> }) {
    const { data } = useMarkdownSnippet(id)
    const locale = useCurrentLocale()
    const { modal, showModal } = useContextualModal()
    const ec = useEditableContext()

    if (!data) return <div>Loading...</div>

    return (
        <div
            style={{ border: `1px dashed ${ColorStyles.primary[600]}`, position: "relative" }}
            onClick={async () =>
                await showModal((close) => (
                    <MarkdownSnippetModal
                        data={data}
                        close={() => close(undefined)}
                        setDirty={() => ec.invalidate()}
                    />
                ))
            }
        >
            {modal}
            <div
                style={{
                    fontSize: 10,
                    backgroundColor: ColorStyles.primary[600],
                    color: "white",
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: "absolute",
                    top: -16,
                    left: -1,
                    height: 16,
                }}
            >
                <Icon
                    icon="ui-sticker-square"
                    size={10}
                    style={{ marginRight: 4, marginTop: -2 }}
                />
                {ClientSideLocalize(data.name)}
            </div>
            <MarkdownView value={data.hydratedSnippet[locale.valueOf()]} />
        </div>
    )
}

function MarkdownSnippetModal({
    close,
    setDirty,
    data,
}: {
    close: () => void
    setDirty: () => void
    data: GetMarkdownSnippetDto
}) {
    const locale = useCurrentLocale()
    return (
        <div
            style={{
                background: "white",
                borderRadius: 8,
                minWidth: 800,
                minHeight: 480,
            }}
        >
            <MarkdownEditor
                style="studio"
                onDrop={async () => undefined}
                value={data.snippet[locale.valueOf()]}
                autoFocus={true}
                onChanged={(newValue) => {
                    data.snippet[locale.valueOf()] = newValue
                    setDirty()
                }}
                markdownSnippetId={data.id}
            />
            <RButton onClick={close}>Close</RButton>
        </div>
    )
}
